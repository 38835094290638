import { setScormData, updateScormData, scormCourseStatus } from "./api.services";
import toast from "react-hot-toast";


const SCORM_API = {
    data: {},
    
    LMSInitialize: function() {
        console.log("LMSInitialize called");
        return "true";
    },
    LMSFinish: function() {
        console.log("LMSFinish called");
        return "true";
    },
    LMSGetValue: function(key) {
        console.log(`LMSGetValue called with key: ${key}`);
        if(this.data[key]){
            return this.data[key];
        }
        else{
            return '';
        }
    },
    LMSSetValue: function(key, value) {
        console.log(`LMSSetValue called with key: ${key}, value: ${value}`);
        this.data[key]=value;
        return "true";
    },
    LMSCommit: function() {
        console.log("LMSCommit called");
        var scormProgress = this.data['scormProgressId']
        if(!scormProgress){
            scormDataPostAPI(this.data)
        }else{
            scormDataUpdateAPI(this.data, scormProgress)
        }

        if(this.data['cmi.core.lesson_status'] === "passed"){
            scormCourseUpdate(this.data)
        }
        return "true";
    },
    LMSGetLastError: function() {
        return "0"; // No error
    },
    LMSGetErrorString: function(errorCode) {
        return "No error"; // Just a default message
    },
    LMSGetDiagnostic: function(errorCode) {
        return "No diagnostic information available";
    }
};
  
const scormDataPostAPI = async(apiData) => {
    let header = {
        Authorization: "Token " + apiData.Token,
    };
    await setScormData(header, apiData)
    .then((resp) => {
        if(resp.status === 201){
            window.API.data['scormProgressId'] = resp.data.id
        }else{
            toast.error('Unable to submit your course progress!')
        }
    })
}

const scormDataUpdateAPI = async(apiData, scormProgress) => {
    let header = {
        Authorization: "Token " + apiData.Token,
    };
    const payload = JSON.stringify(apiData)
    await updateScormData(header, scormProgress, payload)
    .then((resp) => {
        if(resp.status === 200){
            console.log("Progress Saved")
        }else{
            toast.error('Unable to update your course progress!')
        }
    })
}

const scormCourseUpdate = async(apiData) => {
    let header = {
        Authorization: "Token " + apiData.Token,
    };
    let payload = {
        'course_id': apiData['user_course']
    }
    await scormCourseStatus(header, payload)
    .then((resp) => {
        if(resp.status === 200) {
            toast.success(resp.data.message)
        }else{
            toast.error('Unable to update your course progress!')
        }
    })
}


export default SCORM_API;
  
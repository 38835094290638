import React, { useEffect, useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Home.scss";
import homeImage from "../../images/dash_welcome.png";
import { AuthContext } from "../../../App";
import {
  getAnnouncementsData,
  getDashboardData,
} from "../services/api.services";
import { toast } from "react-hot-toast";
import CourseProgress from "../components/CourseProgress";
import moment from "moment";
import liveclass from "../../images/icons/live_class.png";
import {
  FaEllipsisH,
  FaExternalLinkAlt,
  FaShare,
  FaPlayCircle,
  FaGraduationCap,
} from "react-icons/fa";
import RadialProgressBar from "../components/RadialProgressBar";
import startIcon from "../../images/icons/start.png";
import AppLoader from "../components/AppLoader";
import { useDashboardContext } from "../contexts/DashboardContext";
// import ScormCoursePopup from "../components/ScormCoursePopup";
import ScormPlayer from "../components/ScormPlayer";
import ScormCoursePopup from "../components/ScormCoursePopup";

const Home = ({userCourseId, isScorm}) => {
  const { state, dispatch } = useContext(AuthContext);
  const { classProgress, setClassProgress, handleClassProgress, isUpdate, setIsUpdate, openScormCourse, setOpenScormCourse, scormURL} =
    useDashboardContext();
  const [currentCourseProgress, setCurrentCourseProgress] = useState([]);
  const [references, setReferences] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [quizData, setQuizData] = useState({});
  const navigate = useNavigate();
  var userDetail = JSON.parse(localStorage.userDetails);
  var currentCourseId = localStorage.currentCourseId;
  const [loader, setLoader] = useState(false);
  const [scormProgressId, setScormProgressId] = useState(null)

  useEffect(() => {
    handleDashboardData();
  }, [currentCourseId, isUpdate]);

  let header = {
    Authorization: "Token " + state.token,
  };

  const handleDashboardData = async () => {
    await getDashboardData(header, userCourseId)
      .then((resp) => {
        if (resp.data.isSuccess) {
          handleCourseProgress(resp.data.dataInfo);
          handleReferences(resp.data.dataInfo["references"]);
          handleObjectives(resp.data.dataInfo["objectives"]);
          handleQuizData(resp.data.dataInfo["quiz_data"]);
          setScormProgressId(resp.data.dataInfo.scorm_progress);
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");
      });
  };

  const handleQuizData = async (data) => {
    setQuizData(data);
  };

  const handleCourseProgress = async (data) => {
    let courseProgress = [];
    let progress;
    setClassProgress(data);
    Object.entries(data["progress"]).map((value) => {
      if (value[0] !== "Course") {
        let progressData = {};
        progress = (value[1][1] / value[1][0]) * 100; // value[1][1] is the course completed and value[1][0] is the total courses in the module
        progressData[value[0]] = progress;
        courseProgress.push(progressData);
      }
    });
    setCurrentCourseProgress(courseProgress);
  };

  const handleReferences = async (data) => {
    setReferences(data);
  };

  const handleObjectives = async (data) => {
    setObjectives(data);
  };

  const handleNavigation = (courseName, moduleName) => {
    const path = `/${courseName}/${moduleName}`;
    navigate(path);
  };

  return (
    <div className="app-home">
      {loader ? (
        <AppLoader></AppLoader>
      ) : (
        <div className="app-home_body">
          <div className="app-home_body-header">
            <div className="app-home_body-header-text">
              <h4>Hi {userDetail.first_name}, Welcome to Care Learner.</h4>
              <p>{classProgress && classProgress.course_description}</p>
              {classProgress && (
                <button
                  className="start-button"
                  onClick={() =>
                    // handleNavigation(
                    //   classProgress.course_name,
                    //   classProgress.latest_module_name
                    // )
                    handleClassProgress()
                  }
                >
                  {classProgress.is_started ? (
                    <>
                      Continue Learning
                      <img
                        className="app-home_body-header-text-icon"
                        src={startIcon}
                        alt="start"
                      ></img>
                    </>
                  ) : (
                    <>
                      Start Course <FaGraduationCap />
                    </>
                  )}
                </button>
              )}
            </div>
            <div className="app-home_body-header-img">
              <img src={homeImage} alt="home_image"></img>
            </div>
          </div>
          {!isScorm ? 
              <div className="app-home_body-progress">
              <div className="app-home_body-progress-course">
                <div className="app-home_body-progress-course-title">
                  Course Performance
                </div>
                {currentCourseProgress && (
                  <div className="app-home_body-progress-course-content">
                    {currentCourseProgress.map((data) => {
                      return Object.entries(data).map((value, key) => {
                        return (
                          <div
                            key={key}
                            className="app-home_body-progress-course-content-details"
                          >
                            <div className="app-home_body-progress-course-content-details-name">
                              {value[0]}
                            </div>
                            <div className="app-home_body-progress-course-content-details-bar">
                              <CourseProgress completed={value[1]} />
                            </div>
                          </div>
                        );
                      });
                    })}
                  </div>
                )}
              </div>
              <div className="app-home_body-progress-class">
                <div className="app-home_body-progress-class-title">Classes</div>
                {classProgress && (
                  <div className="app-home_body-progress-class-content">
                    <div className="app-home_body-progress-class-content-data">
                      <span className="app-home_body-progress-class-content-data-chapters">
                        {classProgress.completed_chapters}
                      </span>{" "}
                      Out of{" "}
                      <span className="app-home_body-progress-class-content-data-total">
                        {classProgress.total_chapters}
                      </span>
                    </div>
                    <div className="app-home_body-progress-class-content-completed">
                      Completed
                    </div>
                  </div>
                )}
              </div>
              <div className="app-home_body-progress-quiz">
                <div className="app-home_body-progress-quiz-title">Quizes</div>
                <div className="app-home_body-progress-quiz-details">
                  {Object.keys(quizData).length !== 0 && (
                    <RadialProgressBar
                      progressId={null}
                      initialValue={
                        quizData.total_quizzes === 0
                          ? 0  // If there are no quizzes, set initialValue to 0
                          : parseInt((quizData.completed_quizzes / quizData.total_quizzes) * 100)
                      }
                      isComplete={quizData.total_quizzes === quizData.completed_quizzes}
                    />
                  )}
                  {quizData && (
                    <div className="app-home_body-progress-quiz-details-content">
                      <div className="app-home_body-progress-quiz-details-content-data">
                        <span className="app-home_body-progress-quiz-details-content-data-chapters">
                          {quizData.completed_quizzes}
                        </span>{" "}
                        Out of{" "}
                        <span className="app-home_body-progress-quiz-details-content-data-total">
                          {quizData.total_quizzes}
                        </span>
                      </div>
                      <div className="app-home_body-progress-quiz-details-content-completed">
                        Completed
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            : 
            <></>
          }
          <div className="app-home_body-info">
            <div className="app-home_body-info-live">
              <div className="app-home_body-info-live-header">
                <div className="app-home_body-info-live-header-title">
                  References
                </div>
                <div className="app-home_body-info-live-header-icon">
                  <FaEllipsisH />
                </div>
              </div>
              {references ? (
                <div className="app-home_body-info-live-classes">
                  {references.map((value, index) => {
                    return (
                      <a href={value.link} key={index} target="_blank">
                        <div className="app-home_body-info-live-classes-details">
                          <div className="app-home_body-info-live-classes-details-img">
                            <FaExternalLinkAlt
                              style={{
                                width: "20px",
                                height: "20px",
                                // color: "#04157c",
                              }}
                            />
                          </div>
                          <div className="app-home_body-info-live-classes-details-text">
                            {value.reference}
                          </div>
                        </div>
                      </a>
                    );
                  })}
                </div>
              ) : (
                <div className="app-home_body-info-live-empty">
                  References will be provided soon.
                </div>
              )}
            </div>

            <div className="app-home_body-info-announcements">
              <div className="app-home_body-info-announcements-header">
                <div className="app-home_body-info-announcements-header-title">
                  Objectives
                </div>
                <div className="app-home_body-info-announcements-header-icon">
                  <FaEllipsisH />
                </div>
              </div>
              {objectives ? (
                <div className="app-home_body-info-announcements-content">
                  {objectives.map((value, index) => {
                    return (
                      <div
                        className="app-home_body-info-announcements-content-details"
                        key={index}
                      >
                        <div className="app-home_body-info-announcements-content-details-text">
                          <div className="app-home_body-info-announcements-content-details-text-title">
                            • {value.objective}
                          </div>
                          <div className="app-home_body-info-announcements-content-details-text-desc">
                            {value.description}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="app-home_body-info-announcements-empty">
                  Course objectives will be provided soon.
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    {isScorm ? <ScormCoursePopup openScormCourse={openScormCourse} setOpenScormCourse={setOpenScormCourse} scormURL={scormURL} userCourseId={userCourseId} isScorm={isScorm} scormProgressId={scormProgressId} setScormProgressId={()=>setScormProgressId}></ScormCoursePopup> : <></>}
    
    </div>
  );
};

export default Home;
